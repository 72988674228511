import axios from "axios";
import { serverConfig } from "./config";

const fetchMapsData = async (alertId) => {
  try {
    const response = await axios.get(
      `${serverConfig.REACT_APP_ROUTER_SERVICE_URL}/messenger/messages/${alertId}`
    );
    console.log("response.data", response.data.data);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching location data:", error);
  }
};


const fetchAudioList = async (alertId) => {
  try {
    const response = await axios.get(
      `${serverConfig.REACT_APP_ROUTER_SERVICE_URL}/messenger/audio/list/${alertId}`
    );

    console.log('response', response.data);
    return response.data.files;
  } catch (error) {
    console.error('Error fetching server audio list:', error);
    return [];
  }
};




const downloadAudio = async (alertId, segment) => {
  try {
      const downloadUrl = `${serverConfig.REACT_APP_ROUTER_SERVICE_URL}/messenger/audio/download-audio/${alertId}/${segment}`;
      const response = await fetch(downloadUrl);

      if (!response.ok) {
          throw new Error("Failed to download audio");
      }

      const blob = await response.blob(); 
      const audioUrl = URL.createObjectURL(blob); 
      return audioUrl; 
  } catch (error) {
      console.error("Error downloading audio:", error);
      return null;
  }
};

export { fetchMapsData,fetchAudioList,downloadAudio };

/* eslint-disable no-unused-vars */
import React from "react";
import { BsDiscord } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BsTwitter } from "react-icons/bs";
import { BsSlack, BsGithub } from "react-icons/bs";
import playstore from "../assets/images/pay/play.jpg";
import appstore from "../assets/images/pay/app.jpg";

const footer = () => {
  return (
    <>
      <footer className="footer p-5">
        <div className="container-xxl">
          <div className="row justify-content-center justify-content-md-start">
            <div className="col-md-4 col-lg-4 mb-4 mb-md-0 ">
              <h2 className="footer-title mb-3">
                <b>Contact</b>
              </h2>
              <div className="mb-3">
                <p>
                  <b>Addresse:</b> Côte d&apos;Ivoire
                </p>{" "}
              </div>
              <div className="mb-3">
                <p>
                  <b>Téléphone:</b>{" "}
                  <a className="footer-tel" href="tel:+2250709532266">
                    Appeler sur + (225) 07 09 53 22 66
                  </a>
                </p>{" "}
              </div>
              <div className="mb-4">
                <p>
                  <b>Heure :</b> De 8 a.m A 6 p.m
                </p>{" "}
              </div>
              {/* <div className="mb-3">
                <p>
                  <b>Nos réseaux sociaux </b>
                </p>{" "}
              </div>
              <div className="socials d-flex gap-3">
                <Link
                  to="https://discord.com/channels/1027937184768081950/1027937184768081952"
                  id="footer-link"
                  target="_blank"
                  className="gap-3"
                >
                  <BsDiscord />
                </Link>
                <Link
                  to="https://twitter.com/eclarkhalid"
                  id="footer-link"
                  target="_blank"
                  className="gap-3"
                >
                  <BsTwitter />
                </Link>
                <Link
                  to={"https://github.com/Eclarkhalid"}
                  className="gap-3"
                  id="footer-link"
                >
                  <BsGithub />
                </Link>
                <Link
                  to="https://app.slack.com/client/T0195LMKD1R/D04QEBN1J80/rimeto_profile/U04PQHERFM1?cdn_fallback=2"
                  id="footer-link"
                  target="_blank"
                  className="gap-3"
                >
                  <BsSlack />
                </Link>
              </div> */}
            </div>
            <div className="col-md-2 col-lg-2 mb-3 mb-md-0">
              <h2 className="footer-title mb-3">
                <b>Informations</b>
              </h2>
              <div className="mb-3">
                {" "}
                <Link to="/about" id="footer-links">
                  A propos de nous
                </Link>{" "}
              </div>
              <div className="mb-3">
                {" "}
                <Link to="/politique-confidentialite" id="footer-links">
                  Politique de confidentialité
                </Link>{" "}
              </div>
              <div className="mb-3">
                {" "}
                <Link to="/politique-confidentialite" id="footer-links">
                  Termes & Conditions
                </Link>{" "}
              </div>
            </div>
            <div className="col-md-2 col-lg-2 mb-3 mb-md-0">
              <h2 className="footer-title mb-3">
                <b>Support</b>
              </h2>
              <div className="mb-3">
                {" "}
                <Link to="/contact" id="footer-links">
                  Aides
                </Link>{" "}
              </div>
              <div className="mb-3">
                {" "}
                <Link to="/blog" id="footer-links">
                  Blog
                </Link>{" "}
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <h2 className="footer-title mb-3">
                <b>Installer l&apos;application</b>
              </h2>
              <p className="mb-3">
                Disponible sur Google Play Services et App Store
              </p>
              <div className="className='mb-3 col-md-6 col-12 pay">
                <div className="mb-3">
                  <Link
                    to="https://play.google.com/store/apps/details?id=com.smartech.safe"
                    target="_blank"
                  >
                    <img src={playstore} alt="" />
                  </Link>
                </div>
                <div className="mb-3">
                  <Link
                    to="https://apps.apple.com/ca/app/safe-sos/id6740741308"
                    id="footer-links"
                  >
                    <img src={appstore} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-4" />
          <div className="row">
            <div className="col-12 col-md-6">
              <p className="text-center text-md-start">&copy;Safe SOS 2025</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;

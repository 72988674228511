import { useState } from "react";
import WhatsAppChatWidget from "../components/WhatsAppChatWidget";
const Confidentialite = () => {
  const [language, setLanguage] = useState("en");

  const translations = {
    fr: {
      title: "Confidentialité",
      tableOfContents: "Table des Matières",
      introduction: "1. Introduction",
      personalData: "2. Quelles données personnelles utilisons-nous ?",
      secureData: "3. Protégez vos données personnelles",
      paymentServices: "4. Services de paiement",
      deletingData: "5. Suppression des données",
      yourRights: "6. Vos droits",
      translate: "Traduire en Anglais",
      content: {
        introduction:
          "Cette Politique de confidentialité explique comment “SAFE SOS” collecte, utilise et protège les données personnelles que vous nous fournissez ou que nous obtenons dans le cadre de votre utilisation de nos services d’alerte et de messagerie cloud (les « Services »). Dans ce document, les termes « nous », « notre » et « nos » font référence à “SAFE SOS”, tandis que « vous » désigne l’utilisateur des Services.",
        personalData:
          "Nous collectons plusieurs types de données afin d’assurer le bon fonctionnement de notre service et d’optimiser l’efficacité de notre application. Lorsqu’un utilisateur envoie une alerte, nous recueillons automatiquement ses données de localisation via son GPS. Cette collecte permet d’informer les contacts de confiance de la position exacte de l’utilisateur afin de garantir une réaction rapide et adaptée à la situation d’urgence. La précision de ces données est essentielle pour assurer la sécurité de l’utilisateur et permettre aux proches ou aux autorités concernées d’intervenir efficacement. Nous collectons également des données audio sous forme d’extraits enregistrés lors de l’envoi d’une alerte. Ces enregistrements permettent d’apporter un contexte supplémentaire à l’alerte et d’offrir une meilleure compréhension de la situation. L’audio peut être déterminant pour identifier l’urgence d’une situation et aider à une prise de décision rapide par les destinataires de l’alerte. Les messages et conversations échangés via l’application sont stockés afin de faciliter la communication entre les utilisateurs et leurs contacts. Cette conservation permet d’assurer un suivi des échanges, d’améliorer l’expérience utilisateur et de garantir une continuité dans les interactions. Nous prenons des mesures pour protéger ces données et préserver la confidentialité des utilisateurs. Enfin, nous collectons certaines informations de compte, notamment le nom, le numéro de téléphone, l’adresse e-mail et d’autres détails nécessaires à l’identification de l’utilisateur. Ces informations permettent de personnaliser l’expérience utilisateur, de garantir un accès sécurisé aux services et d’assurer un suivi efficace des activités liées aux alertes.",
        secureData:
          "La sécurité et la confidentialité de vos données sont notre priorité absolue. Nous mettons en place des mesures rigoureuses pour garantir la protection de vos informations personnelles contre tout accès non autorisé, toute perte ou toute altération.Stockage des données : Toutes les données collectées sont stockées sur des serveurs sécurisés répondant aux normes les plus strictes en matière de protection des informations. Nous utilisons des infrastructures robustes et des protocoles de sécurité avancés pour prévenir toute intrusion ou fuite de données. Nos serveurs sont surveillés en permanence afin de garantir un niveau de sécurité optimal.Données chiffrées de bout en bout : Afin d'assurer une confidentialité maximale, nous appliquons un chiffrement de bout en bout à toutes les communications et données échangées via notre application. Cela signifie que seules les personnes autorisées peuvent accéder aux informations transmises, empêchant ainsi toute interception ou exploitation non autorisée par des tiers. Conservation des données : Nous conservons vos données uniquement pendant la durée nécessaire pour fournir nos services et respecter nos obligations légales. Une fois cette période écoulée, nous procédons à leur suppression ou anonymisation afin de protéger votre vie privée. Vous avez également la possibilité de demander la suppression de vos informations à tout moment via nos paramètres de confidentialité ou en contactant notre support. Nous nous engageons à maintenir ces standards de protection et à améliorer continuellement nos protocoles pour garantir la sécurité de vos données personnelles.",
        paymentServices:
          "Pour offrir une expérience enrichie et répondre aux besoins spécifiques de nos utilisateurs, nous proposons un service premium permettant d’envoyer des alertes accompagnées d’un enregistrement audio. Ce service est accessible via un abonnement ou un paiement ponctuel, et son activation se fait par l’intermédiaire de prestataires de paiement tiers. Nous collaborons avec des plateformes de paiement sécurisées et reconnues pour garantir la fiabilité des transactions. Lors du processus de paiement, certaines informations telles que vos coordonnées bancaires peuvent être collectées directement par ces prestataires. Nous ne stockons ni n’avons accès à vos données bancaires afin d’assurer une sécurité optimale. Les transactions sont protégées par des protocoles de chiffrement avancés, garantissant la confidentialité et l’intégrité des paiements effectués. En choisissant d’utiliser notre service premium, vous acceptez les conditions générales des prestataires de paiement tiers, qui régissent les transactions et les éventuels remboursements. Nous nous engageons à travailler uniquement avec des partenaires de confiance afin d’assurer une expérience utilisateur fluide et sécurisée pour l’accès à nos services premium.",
        deletingData:
          "Nous accordons une grande importance au respect de votre vie privée et à la maîtrise de vos informations personnelles. Ainsi, vous avez la possibilité de demander la suppression de vos données à tout moment, conformément à la réglementation en vigueur. Pour exercer ce droit, vous pouvez accéder aux paramètres de votre compte et initier une demande de suppression ou contacter notre support client. Une fois la demande validée, nous procédons à l’effacement définitif de vos données de nos systèmes, sauf si leur conservation est requise pour respecter des obligations légales ou résoudre d’éventuels litiges. Les messages, enregistrements audio et informations de localisation stockés dans notre application seront supprimés de manière irréversible après un délai raisonnable suivant la demande de suppression. Toutefois, certaines données anonymisées peuvent être conservées à des fins statistiques ou d’amélioration de nos services. Nous nous engageons à traiter toutes les demandes de suppression dans les meilleurs délais afin de garantir la protection de votre vie privée et le respect de vos droits numériques.",
        yourRights:
          "En tant qu’utilisateur de notre application, vous disposez de plusieurs droits concernant vos données personnelles, conformément aux réglementations en vigueur en matière de protection des données. Droit d’accès : Vous pouvez à tout moment demander des informations sur les données personnelles que nous collectons et traitons vous concernant. Nous vous fournirons un aperçu détaillé des informations enregistrées et de leur utilisation. Droit de rectification : Si certaines de vos données sont inexactes, incomplètes ou obsolètes, vous avez le droit de demander leur correction afin de garantir l’exactitude de vos informations personnelles. Droit de suppression : Vous pouvez demander la suppression définitive de vos données personnelles lorsque celles-ci ne sont plus nécessaires à la prestation de nos services, sous réserve de certaines obligations légales de conservation. Droit d’opposition et de limitation du traitement : Vous avez la possibilité de vous opposer à l’utilisation de vos données personnelles pour certaines finalités ou d’en limiter le traitement en fonction des circonstances. Droit à la portabilité des données : Sur demande, vous pouvez recevoir une copie de vos données personnelles dans un format structuré et couramment utilisé afin de les transférer vers un autre service si vous le souhaitez. Pour exercer l’un de ces droits, vous pouvez nous contacter via les paramètres de votre compte ou en adressant une demande à notre support client. Nous nous engageons à traiter chaque requête dans les meilleurs délais et à respecter vos droits en matière de confidentialité et de protection des données.",
      },
    },
    en: {
      title: "Privacy",
      tableOfContents: "Table of Contents",
      introduction: "1. Introduction",
      personalData: "2. What personal data do we use?",
      secureData: "3. Protect your personal data",
      paymentServices: "4. Payment services",
      deletingData: "5. Deleting data",
      yourRights: "6. Your rights",
      translate: "Translate to French",
      content: {
        introduction:
          "This Privacy Policy explains how “SAFE SOS” collects, uses, and protects the personal data that you provide to us or that we obtain in connection with your use of our cloud messaging and alert services (the “Services”). In this document, the terms “we,” “our,” and “us” refer to “SAFE SOS”, while “you” refers to the user of the Services.",
        personalData:
          "We collect several types of data to ensure the proper functioning of our service and optimize the efficiency of our application. When a user sends an alert, we automatically collect their location data via GPS. This data collection allows us to inform trusted contacts of the user’s exact location to ensure a quick and appropriate response to the emergency situation. The accuracy of this data is essential to ensure the user’s safety and enable family members or relevant authorities to intervene effectively. We also collect audio data in the form of recorded excerpts during an alert. These recordings provide additional context to the alert and offer a better understanding of the situation. The audio can be crucial in identifying the urgency of a situation and assisting in rapid decision-making by the alert recipients. Messages and conversations exchanged via the app are stored to facilitate communication between users and their contacts. This storage helps track interactions, improve user experience, and ensure continuity in communications. We take measures to protect this data and maintain user confidentiality. Finally, we collect certain account information, such as the user’s name, phone number, email address, and other details necessary for user identification. This information allows us to personalize the user experience, ensure secure access to services, and track alert-related activities efficiently.",
        secureData:
          "The security and confidentiality of your data are our top priority. We implement strict measures to ensure the protection of your personal information against unauthorized access, loss, or alteration. Data Storage: All collected data is stored on secure servers that comply with the highest standards of information protection. We use robust infrastructures and advanced security protocols to prevent any intrusion or data breach. Our servers are continuously monitored to ensure optimal security levels. End-to-End Encryption: To ensure maximum confidentiality, we apply end-to-end encryption to all communications and data exchanged through our application. This means that only authorized persons can access the transmitted information, preventing any interception or unauthorized exploitation by third parties. Data Retention: We retain your data only for the time necessary to provide our services and meet our legal obligations. Once this period has passed, we delete or anonymize the data to protect your privacy. You also have the option to request the deletion of your information at any time through our privacy settings or by contacting our support team. We are committed to maintaining these protection standards and continuously improving our protocols to ensure the security of your personal data.",
        paymentServices:
          "To provide an enhanced experience and meet the specific needs of our users, we offer a premium service that allows sending alerts with an audio recording. This service is available through a subscription or one-time payment, and its activation is done via third-party payment providers. We collaborate with secure and trusted payment platforms to ensure the reliability of transactions. During the payment process, certain information such as your banking details may be collected directly by these providers. We do not store or have access to your banking information to ensure optimal security. Transactions are protected by advanced encryption protocols, ensuring the confidentiality and integrity of the payments made. By choosing to use our premium service, you agree to the terms and conditions of the third-party payment providers, which govern transactions and any refunds. We are committed to working only with trusted partners to ensure a smooth and secure user experience when accessing our premium services.",
        deletingData:
          "We highly value your privacy and your control over your personal information. Therefore, you have the right to request the deletion of your data at any time, in accordance with applicable regulations. To exercise this right, you can access your account settings to initiate a deletion request or contact our customer support team. Once the request is validated, we will permanently erase your data from our systems unless its retention is required to comply with legal obligations or resolve potential disputes. Messages, audio recordings, and location information stored in our application will be irreversibly deleted within a reasonable timeframe following your deletion request. However, some anonymized data may be retained for statistical purposes or to improve our services. We are committed to processing all deletion requests as quickly as possible to ensure your privacy protection and compliance with your digital rights.",
        yourRights:
          "As a user of our application, you have several rights regarding your personal data, in accordance with applicable data protection regulations. Right of access: You can request information at any time about the personal data we collect and process about you. We will provide you with a detailed overview of the stored information and how it is used. Right to rectification: If any of your data is inaccurate, incomplete, or outdated, you have the right to request its correction to ensure the accuracy of your personal information. Right to erasure: You can request the permanent deletion of your personal data when it is no longer necessary for the provision of our services, subject to certain legal retention obligations. Right to object and restrict processing: You have the option to object to the use of your personal data for certain purposes or to limit its processing depending on the circumstances. Right to data portability: Upon request, you can receive a copy of your personal data in a structured and commonly used format to transfer it to another service if you wish. To exercise any of these rights, you can contact us through your account settings or by submitting a request to our customer support team. We are committed to processing each request as quickly as possible and respecting your rights regarding privacy and data protection.",
      },
    },
  };

  const currentText = translations[language];

  return (
    <>
      <section className="about-app p-5">
        <button
          className="mb-3 p-3 bg-blue-500 text-gray-800 rounded text-lg"
          onClick={() => setLanguage(language === "en" ? "fr" : "en")}
        >
          {currentText.translate}
        </button>
        <div className="row">
          {/* Table des Matières fixe */}
          {/* <div className="col-md-4 position-fixed">
            <h2 className="text-lg font-bold mb-3 text-gray-800 border-l-4 border-blue-500 pl-2">
              {currentText.tableOfContents}
            </h2>
            <ul className="space-y-2 text-blue-600 text-lg">
              <li>
                <a href="#introduction" className="hover:underline">
                  {currentText.introduction}
                </a>
              </li>
              <li>
                <a
                  href="#what-personal-data-we-use"
                  className="hover:underline"
                >
                  {currentText.personalData}
                </a>
              </li>
              <li>
                <a href="#secure-data" className="hover:underline">
                  {currentText.secureData}
                </a>
              </li>
              <li>
                <a
                  href="#third-party-payment-services"
                  className="hover:underline"
                >
                  {currentText.paymentServices}
                </a>
              </li>
              <li>
                <a href="#deleting-data" className="hover:underline">
                  {currentText.deletingData}
                </a>
              </li>
              <li>
                <a href="#your-rights" className="hover:underline">
                  {currentText.yourRights}
                </a>
              </li>
            </ul>
          </div> */}

          {/* Content scrollable */}
          <div
            className="col-md-6 offset-md-4"
            style={{ maxHeight: "80vh", overflowY: "auto" }}
          >
            <h1 className="text-2xl font-bold text-orange-600 mb-4">
              {currentText.title}
            </h1>

            <section id="introduction" className="mb-6">
              <h2 className="text-xl font-semibold text-blue-600">
                {currentText.introduction}
              </h2>
              <p className="text-gray-700 mt-2">
                {currentText.content.introduction}
              </p>
            </section>

            <section id="what-personal-data-we-use" className="mb-6">
              <h2 className="text-xl font-semibold text-blue-600">
                {currentText.personalData}
              </h2>
              <p className="text-gray-700 mt-2">
                {currentText.content.personalData}
              </p>
            </section>

            <section id="secure-data" className="mb-6">
              <h2 className="text-xl font-semibold text-blue-600">
                {currentText.secureData}
              </h2>
              <p className="text-gray-700 mt-2">
                {currentText.content.secureData}
              </p>
            </section>

            <section id="third-party-payment-services" className="mb-6">
              <h2 className="text-xl font-semibold text-blue-600">
                {currentText.paymentServices}
              </h2>
              <p className="text-gray-700 mt-2">
                {currentText.content.paymentServices}
              </p>
            </section>

            <section id="deleting-data" className="mb-6">
              <h2 className="text-xl font-semibold text-blue-600">
                {currentText.deletingData}
              </h2>
              <p className="text-gray-700 mt-2">
                {currentText.content.deletingData}
              </p>
            </section>

            <section id="your-rights" className="mb-6">
              <h2 className="text-xl font-semibold text-blue-600">
                {currentText.yourRights}
              </h2>
              <p className="text-gray-700 mt-2">
                {currentText.content.yourRights}
              </p>
            </section>
          </div>
        </div>
      </section>
      <WhatsAppChatWidget />
    </>
  );
};

export default Confidentialite;

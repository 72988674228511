import React from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/layout";
import Home from "./pages/home";
import About from "./pages/about";
import Confidentialite from "./pages/confidentialite";
import Contact from "./pages/contact";
import Progressing from "./pages/progressing";
import Blog from "./pages/blog";
import MapPage from "./pages/sos/page";
import "./App.css";

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="blog" element={<Blog />} />
            <Route path="about" element={<About />} />
            <Route
              path="politique-confidentialite"
              element={<Confidentialite />}
            />
            <Route path="contact" element={<Contact />} />
            <Route path="progressing" element={<Progressing />} />
            <Route path="sos" element={<MapPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

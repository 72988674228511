import React, { useEffect, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { fetchMapsData, fetchAudioList, downloadAudio } from "../../api";
import PropTypes from "prop-types";
import { serverConfig } from "../../api/config";
import { FaEye, FaEyeSlash } from "react-icons/fa"; 

const GoogleMaps = ({ alertId }) => {
    const mapRef = React.useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [routeCoordinates, setRouteCoordinates] = useState([]);
    const [audios, setAudios] = useState([]);
    const [showAudioSection, setShowAudioSection] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                const data = await fetchMapsData(alertId);
                console.log("data", data);
                const { gpsCoordinates, endTime } = data.contentJson;
                setRouteCoordinates(
                    gpsCoordinates.map(({ latitude, longitude }) => ({
                        lat: parseFloat(latitude),
                        lng: parseFloat(longitude),
                    }))
                );

                const audioFiles = await fetchAudioList(alertId);
                console.log("audioFiles", audioFiles);

                if (audioFiles.length > 0) {
                    const downloadedAudios = await Promise.all(
                        audioFiles.map(async (file) => {
                            const segment = parseInt(file.split("_")[1].split(".mp3")[0], 10);
                            const audioUrl = await downloadAudio(alertId, segment);
                            return audioUrl;
                        })
                    );

                    const validAudios = downloadedAudios.filter((audio) => audio !== null);
                    setAudios(validAudios);
                } else {
                    setAudios([]);
                }

                if (endTime) {
                    console.log("Alert ended, stopping fetch intervals.");
                    clearInterval(intervalRef.current);
                }
            } catch (error) {
                console.error("Error fetching location data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        const intervalRef = { current: null };

        getData();

        intervalRef.current = setInterval(getData, 60000);

        return () => clearInterval(intervalRef.current);
    }, [alertId]);

    useEffect(() => {
        const loader = new Loader({
            apiKey: serverConfig.REACT_APP_GOOGLE_MAPS_API_KEY,
            version: "weekly",
        });

        loader
            .load()
            .then(() => {
                if (mapRef.current) {
                    const map = new google.maps.Map(mapRef.current, {
                        zoom: 5,
                        center: routeCoordinates.length
                            ? routeCoordinates[0]
                            : { lat: 39.60128890889341, lng: -9.069839810859907 },
                    });

                    if (routeCoordinates.length > 0) {
                        const path = new google.maps.Polyline({
                            path: routeCoordinates,
                            geodesic: true,
                            strokeColor: "#FF0000",
                            strokeOpacity: 1.0,
                            strokeWeight: 4,
                        });
                        path.setMap(map);

                        routeCoordinates.forEach((coordinate, index) => {
                            const marker = new google.maps.Marker({
                                position: coordinate,
                                map,
                            });

                            const infowindow = new google.maps.InfoWindow({
                                content: `
                                <div style="color: black; background-color: white;">
                                    <h3>Informations du lieu</h3>
                                    <p>Position : ${index + 1}</p>
                                    <p>Latitude: ${coordinate.lat}</p>
                                    <p>Longitude: ${coordinate.lng}</p>
                                </div>
                            `,
                            });

                            marker.addListener("click", () => {
                                infowindow.open(map, marker);
                            });
                        });
                    }
                } else {
                    console.error("Map container (mapRef) is not available.");
                }
            })
            .catch((error) => {
                console.error("Error loading Google Maps API:", error);
            });
    }, [routeCoordinates]);

    const toggleAudioSection = () => {
        setShowAudioSection((prev) => !prev);
    };

    return (
        <div style={{ display: "flex", height: "100vh", marginTop: "50px", marginBottom: "70px" }}>
            <div
                style={{
                    flex: showAudioSection ? 5 : 1,
                    height: "100%",
                    marginRight: showAudioSection ? "20px" : "0",
                }}
                ref={mapRef}
            ></div>

            {showAudioSection && (
                <div style={{ flex: 1, padding: "20px", borderLeft: "1px solid #ccc", maxWidth: "300px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                        <h3 style={{ margin: 0 }}>Playlist Audio</h3>
                        <button
                            onClick={toggleAudioSection}
                            style={{
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                fontSize: "30px",
                                color: "#007bff",
                                position: "relative",
                            }}
                            title="Masquer la section audio" 
                        >
                            <FaEyeSlash /> 
                        </button>
                    </div>

                    {isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        <div>
                            {audios.length > 0 ? (
                                audios.map((audio, index) => (
                                    <div key={index} style={{ marginBottom: "10px" }}>
                                        <audio controls style={{ width: "100%" }}>
                                            <source src={audio} type="audio/mpeg" />
                                            Votre navigateur ne supporte pas lélément audio.
                                        </audio>
                                    </div>
                                ))
                            ) : (
                                <p>Aucun audio disponible.</p>
                            )}
                        </div>
                    )}
                </div>
            )}

            {!showAudioSection && (
                <button
                    onClick={toggleAudioSection}
                    style={{
                        position: "fixed",
                        right: "20px",
                        top: "100px",
                        zIndex: 1000,
                        padding: "10px",
                        backgroundColor: "#007bff",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                    }}
                >
                    <FaEye /> 
                    Afficher la section audio
                </button>
            )}
        </div>
    );
};

GoogleMaps.propTypes = {
    alertId: PropTypes.string.isRequired,
};

export default GoogleMaps;